
import { OrderingSubjectView } from "@/models/entities/ordering-subject-view.interface";
import { OrderingSubject } from "@/models/entities/ordering-subject.interface";
import store from "@/store";
import AuthModule from "@/store/modules/auth.module";
import OverlayModule from "@/store/modules/overlay.module";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { orderingSubjectService } from "@/services/api/ordering-subject.service";
import { required, iban } from "@/helpers/rules";
import { SnackbarModule } from "@zelando/vuelando";
import IBANTTT from "iban";

const authModule = getModule(AuthModule, store);

const overlayModule = getModule(OverlayModule, store);
const snackbarModule = getModule(SnackbarModule, store);

@Component
export default class ModifyOrderingSubject extends Vue {
  @Prop({ required: true, default: null, type: Number })
  private orderingSubjectId: number;

  @Prop({ required: false, default: null, type: Array })
  private orderingSubjectView: OrderingSubjectView[];

  private keyDialog = false;
  private cucDialog = false;

  private isFormValid = false;

  private deleteDialog = false;

  private orderingSubject: OrderingSubject = {
    orderingSubjectID: -1,
    cucCode: "",
    companyName: "",
    companyFullName: "",
    companyAddress: "",
    companyZipCode: "",
    companyCity: "",
    companyProvince: "",
    companyVatNumber: "",
    companyIban: "",
    controlKeys: "",
    userID: 0,
    createdOn: new Date(),
    createdBy: 0,
    modifiedOn: new Date(),
    modifiedBy: 0,
    isDefault: false,
    multipleRow: false,
  };
  private username = authModule.userName;

  private required = required;
  private iban = iban;
  private isEdit = false;
  private newOrderingSubject = false;

  async mounted(): Promise<void> {
    await this.changeOrderingSubjectId();
  }
  private async save() {
    overlayModule.showOverlay();
    this.orderingSubject.companyName.trim();
    this.orderingSubject.companyFullName.trim();
    this.orderingSubject.companyIban.trim();
    let id = (
      await orderingSubjectService.AddOrderingSubjects(this.orderingSubject)
    ).data;
    if (this.newOrderingSubject) {
      snackbarModule.showSnackbar({
        message: this.$tc("orderingSubject.OrderingSubjectSaved"),
        type: "success",
        timer: 5000,
      });
    } else {
      snackbarModule.showSnackbar({
        message: this.$tc("orderingSubject.OrderingSubjectEdited"),
        type: "success",
        timer: 5000,
      });
    }
    this.$emit("save", id);
    this.isEdit = false;
  }

  private async getAllById(orderingSubjectId: number) {
    await orderingSubjectService.getAllById(orderingSubjectId).then((x) => {
      this.orderingSubject = x.data;
    });
  }

  @Watch("orderingSubjectId")
  private orderingSubjectIdChange() {
    this.changeOrderingSubjectId();
  }
  private async cancel() {
    this.$emit("cancel");

    if (this.orderingSubjectView.length == 0) {
      this.isEdit = false;
      this.newOrderingSubject = false;
      this.orderingSubject = {
        orderingSubjectID: -1,
        cucCode: "",
        companyName: "",
        companyFullName: "",
        companyAddress: "",
        companyZipCode: "",
        companyCity: "",
        companyProvince: "",
        companyVatNumber: "",
        companyIban: "",
        controlKeys: "",
        userID: 0,
        createdOn: new Date(),
        createdBy: 0,
        modifiedOn: new Date(),
        modifiedBy: 0,
        isDefault: false,
        multipleRow: false,
      };
    } else {
      this.changeOrderingSubjectId();
    }
  }

  private insertOrderingSubject() {
    this.newOrderingSubject = true;
    this.isEdit = true;
  }
  private changeOrderingSubjectId() {
    if (this.orderingSubjectId == -1) {
      this.orderingSubject.orderingSubjectID = -1;
      this.orderingSubject = {
        orderingSubjectID: -1,
        cucCode: "",
        companyName: "",
        companyFullName: "",
        companyAddress: "",
        companyZipCode: "",
        companyCity: "",
        companyProvince: "",
        companyVatNumber: "",
        companyIban: "",
        controlKeys: "",
        userID: 0,
        createdOn: new Date(),
        createdBy: 0,
        modifiedOn: new Date(),
        modifiedBy: 0,
        isDefault: false,
        multipleRow: false,
      };
      this.isEdit = true;
      this.newOrderingSubject = true;
    } else if (this.orderingSubjectId == 0) {
      this.newOrderingSubject = false;
    } else {
      this.getAllById(this.orderingSubjectId);
      this.isEdit = false;
      this.newOrderingSubject = false;
    }
  }

  private Edit() {
    this.isEdit = true;
  }

  private openKeyDialog() {
    this.keyDialog = true;
  }
  private openCucDialog() {
    this.cucDialog = true;
  }

  private async deleteOrderingSubjects() {
    overlayModule.showOverlay();
    await orderingSubjectService.DeleteOrderingSubjects(this.orderingSubjectId);
    snackbarModule.showSnackbar({
      message: this.$tc("orderingSubject.OrderingSubjectDeleted"),
      type: "success",
      timer: 5000,
    });
    this.orderingSubject = {
      orderingSubjectID: -1,
      cucCode: "",
      companyName: "",
      companyFullName: "",
      companyAddress: "",
      companyZipCode: "",
      companyCity: "",
      companyProvince: "",
      companyVatNumber: "",
      companyIban: "",
      controlKeys: "",
      userID: 0,
      createdOn: new Date(),
      createdBy: 0,
      modifiedOn: new Date(),
      modifiedBy: 0,
      isDefault: false,
      multipleRow: false,
    };
    this.$emit("delete");
    this.deleteDialog = false;
    this.isEdit = false;
  }

  private checkIban(iban: string): boolean[] {
    var IBAN = IBANTTT;
    var valid: boolean[] = [];
    valid.push(IBAN.isValid(iban));
    return valid;
  }

  private disabledSnackbar() {
    snackbarModule.showSnackbar({
      message: this.$tc("orderingSubject.InsertData"),
      type: "error",
      timer: 5000,
    });
  }
}
